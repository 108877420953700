.about-me {
  /*background-color: #c2bbbb;*/

  background: linear-gradient(#dbd3d3, #c2bbbb);

  & .paragraph {
    font-size: 1.8rem;
  }
  .about-me--img {
    max-width: 100%;
    max-height: 100%;
  }
}
