.title-container {
  margin-bottom: 2.5rem;
  & .title-black {
    position: relative;
    padding-bottom: 0.4rem;
    &::after {
      content: "";
      position: absolute;
      width: 34%;
      height: 4px;
      background: rgb(65, 65, 65);
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  & .title-white {
    position: relative;
    padding-bottom: 1rem;
    &::after {
      content: "";
      position: absolute;
      width: 34%;
      height: 4px;
      background: rgb(196, 196, 196);
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
}
