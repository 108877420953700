.contact-me {
  //background-color: #c2bbbb;
  background: linear-gradient(to bottom, #dbd3d3, #c2bbbb);

  textarea {
    resize: none !important;
  }
  label {
    font-size: 1.5rem;
  }

  .form-group small {
    color: rgb(255, 0, 0);
    font-size: 1.3rem;
    /*Visible vs Hidden*/
    /*Colapsa todo alrededor y hidden solo lo oculta*/
    //visibility: hidden;
    display: none;
  }

  .form-group.error small {
    display: block;
  }

  .fields {
    font-size: 1.5rem;
  }
  .send-button {
    font-size: 1.5rem;
  }
  .letter-container {
    display: flex;
    justify-content: center;
    padding-left: 6rem;

    .img-letter {
      width: 65%;
      justify-self: center;
    }
  }
}

/*Modal*/
.modal-header-styles {
  font-size: 2.4rem;
}

.modal-body-styles {
  font-size: 1.5rem;
}
.modal-button-styles {
  font-size: 1.5rem;
}
