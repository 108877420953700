$projects-background: #ebebeb;

.projects-container {
  /*background-color: #c0c0c0;*/
  background-color: #252934;
  //background:linear-gradient(#c2bbbb,#dbd3d3);
  padding-bottom: 6rem;
  h2 {
    color: white;
  }
}
