.triangle {
  border: none;
  padding-top: 2.2rem;
}

.triangle-container-reverse {
  clip-path: polygon(0% 0%, 50% 100%, 100% 0%, 100% 101%, 0% 101%);
}
.triangle-container {
  clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 50% 0%, 0% 100%);
}
